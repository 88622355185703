import React, { useState, useEffect } from 'react';
import bear from '../assets/game_pngs/avatars/Oops.png';
import { Link } from 'react-router-dom';
import '../styles/Oops.css';

export default function Oops() {
  const [headerHeight, setHeaderHeight] = useState(0);

  // Calculate the height of the main content dynamically
  useEffect(() => {
    const headerElement = document.querySelector('.primary-header');
    if (headerElement) {
      setHeaderHeight(headerElement.offsetHeight);
    }
  }, []);

  return (
    <div className="oops-container" style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
      {/* mobile view */}
      <div className="mobile-view">
        <img src={bear} alt="bear and friends" style={{ width: '20rem' }}></img>
        <h2 className="oops">Oops!</h2>
        <p className="mobile-error">
          Game support is not yet available for mobile.
          <br />
          <br />
          Please visit our other pages through the menu!
        </p>
        <Link to="/" className="pink home-link">
          HOME
        </Link>
      </div>
    </div>
  );
}