import React from "react";
import { Link } from 'react-router-dom';

import '../styles/GameAssets.css';
import back from '../assets/game_pngs/navigation/back.png';

export default function GameAssets() {
  return (
    <div className="Credits">

    <div className="game-ass-header yellow" style={{fontSize: '40px'}}>Game Assets Continued</div>

      <div className="credit-flex">

        <div>
          <div className="credit-div">
            <h1 className="credit-header pink">Kenney</h1>
            <p className="credit-body"> 
              <Link to="https://poly.pizza/m/y4ZU5S7RuD" className="credit-link" target="_blank">Coffee table</Link>
            </p>
            <p className="credit-body"> 
              <Link to="https://poly.pizza/m/6PbVkqPzEU" className="credit-link" target="_blank">Desk</Link>
            </p>
            <p className="credit-body">
              <Link to="https://poly.pizza/bundle/Furniture-Kit-NoG1sEUD1z" className="credit-link" target="_blank">Furniture kit</Link>
            </p>
            <p className="credit-body"> 
              <Link to="https://poly.pizza/m/iNvqUZRjev" className="credit-link" target="_blank">Shaker salt</Link>
            </p>
            <p className="credit-body">
              <Link to="https://poly.pizza/m/R7Iq0qPgdr" className="credit-link" target="_blank">Side table</Link>
            </p>
          </div>

          <div className="credit-div">
            <h1 className="credit-header green">Zsky</h1>
            <p className="credit-body"> 
              <Link to="https://poly.pizza/m/F8zLx6wGG8" className="credit-link" target="_blank">Cactus flower pot</Link>
            </p>
            <p className="credit-body"> 
              <Link to="https://poly.pizza/m/Kgt363WkKd" className="credit-link" target="_blank">Flower pot</Link>
            </p>
          </div>
        </div>

        <div className="credit-div">
          <h1 className="credit-header blue">Jeremy</h1>
          <p className="credit-body"> 
            <Link to="https://poly.pizza/m/8mkAgVYGbL4" className="credit-link" target="_blank">Bed</Link>
          </p>
          <p className="credit-body"> 
            <Link to="https: //poly.pizza/m/9x50CBKNzPq" className="credit-link" target="_blank">Bulletin board</Link>
          </p>
          <p className="credit-body">
            <Link to="https://poly.pizza/m/2yHE0n1ahjp" className="credit-link" target="_blank">Calendar</Link>
          </p>
          <p className="credit-body"> 
            <Link to="https://poly.pizza/m/25ZKdEUu2MR" className="credit-link" target="_blank">I really like to stack things</Link>
          </p>
          <p className="credit-body">
            <Link to="https://poly.pizza/m/8-vPv5_yqKb" className="credit-link" target="_blank">Oriental rug</Link>
          </p>
          <p className="credit-body">
            <Link to="https://poly.pizza/m/fTsh4nKZ9iK" className="credit-link" target="_blank">Rock band poster</Link>
          </p>
          <p className="credit-body"> 
            <Link to="https://poly.pizza/m/93snYMCAMEb" className="credit-link" target="_blank">Table</Link>
          </p>
          <p className="credit-body">
            <Link to="https://poly.pizza/m/0CiZ4f1cZaF" className="credit-link" target="_blank">Wall painting</Link>
          </p>
        </div>

        <div>

        <div className="credit-div">
          <h1 className="credit-header green">Jakers_H</h1>
          <p className="credit-body">
            <Link to="https://poly.pizza/m/fK35-pL3_Ub" className="credit-link" target="_blank">Cabinets -</Link> Butcher's place
          </p>
          <p className="credit-body"> 
            <Link to="https://poly.pizza/m/bwL7Xvmql74" className="credit-link" target="_blank">Fridge and cooker</Link>
          </p>
          <p className="credit-body">
            <Link to="https://poly.pizza/m/5COCzyz489J" className="credit-link" target="_blank">Plants -</Link> Assorted shelf plants
          </p>
        </div>

        <div className="credit-div">
          <h1 className="credit-header pink">Quaternius</h1>
          <p className="credit-body"> 
            <Link to="https://poly.pizza/m/kLViSk9EhX" className="credit-link" target="_blank">Chair</Link>
          </p>
          <p className="credit-body"> 
            <Link to="https://poly.pizza/m/UfKvrZBK6C" className="credit-link" target="_blank">Office chair</Link>
          </p>
        </div>

        </div>

        <div className="credit-div">
          <h1 className="credit-header blue">Misc Creators</h1>
          <p className="credit-body">Book stack by 
            <Link to="https://poly.pizza/m/1WggoIFq8tx" className="credit-link" target="_blank"> Danni Bittman</Link>
          </p>
          <p className="credit-body">Drafting table by 
            <Link to="https://poly.pizza/m/8Z4yYj39NWS" className="credit-link" target="_blank"> Joshua Johnson</Link>
          </p>
          <p className="credit-body">Drumkit by 
            <Link to="https://poly.pizza/m/4l5jRtpTec" className="credit-link" target="_blank"> J-Toastie</Link>
          </p>
          <p className="credit-body">Drummers drumming stool by 
            <Link to="https://poly.pizza/m/eKdSu9Hwc-I" className="credit-link" target="_blank"> Peter Simcoe</Link>
          </p>
          <p className="credit-body">Elliptical by 
            <Link to="https://poly.pizza/m/af2eIR99bR0" className="credit-link" target="_blank"> S. Paul Michael</Link>
          </p>
          <p className="credit-body">Fish tank by 
            <Link to="https://poly.pizza/m/2Rb9zxgkDEM" className="credit-link" target="_blank"> Chris Ross</Link>
          </p>
          <p className="credit-body">Living room by 
            <Link to="https://poly.pizza/m/7Ma5B10CZLA" className="credit-link" target="_blank"> Alex Safayan</Link>
          </p>
          <p className="credit-body">Little bookcase by 
            <Link to="https://poly.pizza/m/74tXfxoDB9b" className="credit-link" target="_blank"> Don Carson</Link>
          </p>
          <p className="credit-body">Office chair by 
            <Link to="https://poly.pizza/m/3txPAhYeu-x" className="credit-link" target="_blank"> Ryan Donaldson</Link>
          </p>
          <p className="credit-body">Taylor reflex hammer by 
            <Link to="https://sketchfab.com/3d-models/taylor-reflex-hammer-7ea57c147d26408aba7c420ce476698f" className="credit-link" target="_blank"> Simon Pasi</Link>
          </p>
          <p className="credit-body">Workbench by 
            <Link to="https://poly.pizza/m/24I9X8aeWTR" className="credit-link" target="_blank"> Sirkitree</Link>
          </p>
          <p className="credit-body">VR mobil by 
            <Link to="https://poly.pizza/m/6XXj6DSBb08" className="credit-link" target="_blank"> Vladimir Ilic</Link>
          </p>
        </div>

      </div>
      <div className="credit-header cream jump" style={{fontSize: '20px', height: 'fit-content'}}>
          <Link to="/credits" className="back-link">
            <img src={back} alt="back-arrow" className="plus-sign jump" style={{height: '35px'}} /> Back to credits</Link></div>
    </div>
  )
}