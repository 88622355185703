import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

/* images and styles */
import '../styles/Credits.css';
import plus from '../assets/game_pngs/navigation/yellow-plus.png';
import cat from '../assets/game_pngs/avatars/Cat.png';

export default function Credits() {
  const [ headerHeight, setHeaderHeight ] = useState(0);

  // Calculate the height of the main content dynamically
  useEffect(() => {
    const headerHeight = document.querySelector('.primary-header').offsetHeight;
    setHeaderHeight(`calc(100vh - ${headerHeight}px)`);
  }, []);

  return (
    <div className="Credits" style={{ height: headerHeight }}>

      <div className="credit-flex">
        <div className="credit-div">
          <h1 className="credit-header pink">Fonts</h1>
          <p className="credit-body">Super School by 
            <Link to="https://www.cufonfonts.com/font/super-school" className="credit-link" target="_blank"> PUTRACETOL</Link>
          </p>
          <p className="credit-body">Letters for Learners by 
            <Link to="https://www.dafont.com/letters-for-learners.font" className="credit-link" target="_blank"> Brittney Murphy Design</Link>
          </p>
          <p className="credit-body">Circular Standard Font by 
            <Link to="https://www.freefonts.io/circular-std-font/" className="credit-link" target="_blank"> LINETO</Link>
          </p>
        </div>

        <div className="credit-div">
          <h1 className="credit-header blue">Web Content</h1>
          <p className="credit-body">Github and LinkedIn icons from 
            <Link to="https://www.icons8.com/" className="credit-link" target="_blank"> icons8</Link>
          </p>
          <p className="credit-body">Cloud background from
            <Link to="https://images.app.goo.gl/kj3ixrWdkLZteP9X9" className="credit-link" target="_blank"> Freepik</Link>
          </p>
          <p className="credit-body">Various avatars and icons by 
            <Link to="https://assetstore.unity.com/packages/2d/gui/animal-world-gui-pack-62974" className="credit-link" target="_blank"> ricimi</Link>
          </p>
          <p className="credit-body">Web browser frames from
            <Link to="https://www.canva.com" className="credit-link" target="_blank"> Canva</Link>
          </p>
        </div>

        <div className="credit-div">
          <h1 className="credit-header green">Game Assets</h1>
          <p className="credit-body">AI NPC Engine v.3.0 by 
            <Link to="https://assetstore.unity.com/packages/tools/behavior-ai/ai-npc-engine-v-3-0-dialogue-behavior-for-unity-inworld-229406" className="credit-link" target="_blank"> InWorld AI</Link>
          </p>
          <p className="credit-body">Animal World GUI Pack by 
            <Link to="https://assetstore.unity.com/packages/2d/gui/animal-world-gui-pack-62974" className="credit-link" target="_blank"> ricimi</Link>
          </p>
          <p className="credit-body">Cartoon Town - Low Poly Assets by 
            <Link to="https://assetstore.unity.com/packages/3d/environments/cartoon-town-low-poly-assets-74020" className="credit-link" target="_blank"> ricimi</Link>
          </p>
          <p className="credit-body">Cartoon Hospital Assets by 
            <Link to="https://assetstore.unity.com/packages/3d/environments/urban/cartoon-hospital-assets-148186" className="credit-link" target="_blank"> A.R.S|T.</Link>
          </p>
          <p className="credit-body">Low Poly Humanized Animals by 
            <Link to="https://assetstore.unity.com/packages/3d/characters/animals/low-poly-humanized-animals-186803" className="credit-link" target="_blank"> DreamSheepS_Studio</Link>
          </p>
          <p className="more-div">
            <Link to="/game-assets" className="credit-link"><img src={plus} className="plus-sign" style={{height: '25px'}} alt="plus-sign" /> MORE ASSETS</Link>
          </p>
        </div>
      </div>
      <div className="thank-you-msg-div">        
        <img src={cat} alt="cat-icon" style={{height: '218px', marginBottom: '0'}}></img>
        <div className="credit-header yellow thank-you-msg">Thank you!</div>
      </div>

    </div>
  )
}