import React from 'react'

/* styles and images */
import '../styles/About.css';
import logo from '../assets/images/sv-logoTM.png'

/* components */
import AboutCard from './AboutCard';
import aboutCardsData from './aboutCardsData';
import BackToTop from './BackToTop';

function About() {
  return (
    <div className="about-page">
      <div className="flex">
        <h1 className="green-h1">About the team</h1>
      </div>
      <div className="about-cards-container about-flex">
        {aboutCardsData.map((cardData, index) => (
          <AboutCard 
            key={index}
            image={cardData.image}
            name={cardData.name}
            spec={cardData.spec}
            description={cardData.description}
            github={cardData.github}
            linkedin={cardData.linkedin}
            website={cardData.website}
            link={cardData.link} />
        ))}
      </div>
      <div className="flex">
        <h1 className="pink-h1">About the game</h1>
      </div>
      <div className="text-block-div">
        <p className="text-block line-height">
          <img src={logo} alt="sv-logo tiny" className="jump" style={{height: '50px'}} /> is designed to inspire children to explore, learn, and 
          contribute to their community with confidence and curiosity. Our vision 
          is to cultivate a passion for learning that transcends the game, empowering 
          children to approach real-world challenges with enthusiasm and competence!
          </p>

          <p className="text-block line-height">
          In SkillVille, children embark on an immersive journey through a friendly 
          virtual town, where they engage in fun and educational activities. By simulating 
          real-world tasks like volunteering at the hospital, players develop practical skills 
          and knowledge in areas such as health and communication, tailored to their age group. 
          SkillVille utilizes positive reinforcement learning to reward children for their 
          achievements, motivating them to continue their exploration and growth!
          </p>
        </div>
        <BackToTop />
    </div>
  );
};

export default About