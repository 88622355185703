/* functional imports */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

/* styles and images */
import '../styles/Landing.css';
import logo from '../assets/images/sv-logoTM.png';
import play from '../assets/images/play.png';

export default function Landing() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 560); 
    };

    handleResize(); 
    window.addEventListener('resize', handleResize); 

    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);

  return (
    <div className="Landing">
      <div className="Cloud-background"></div>
      <div className="Content">
        <img src={logo} className="Landing-logo" alt="SkillVille" />
        <p className="Learn">Learn and Grow</p>
      </div>
      <div className="Content">
      <Link to="/game">
          <div className={`Play-btn ${isMobile ? 'Play-btn-mobile' : ''}`}>
            {isMobile ? (
              <img src={play} alt="Play" className="Play-btn-image" />
            ) : (
              'PLAY'
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}