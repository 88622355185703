/* functional imports */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

/* styles and images */
import '../styles/Header.css';
import logo from '../assets/images/sv-logoTM.png'
import bear from '../assets/game_pngs/animals/round/Bear.png';
import welcome from '../assets/game_pngs/navigation/welc-no-outline.png';
import close from '../assets/game_pngs/navigation/Crossmark.png';

export default function Header() {
  // if landing page hide header logo
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const [ hiddenMenu, setHiddenMenu ] = useState(true);
  const toggleMenu = () => {
    setHiddenMenu(!hiddenMenu);
  };

  const handleLinkClick = () => {
    setHiddenMenu(true); // Hide the menu when a link is clicked
  };

  return (
    <header className={`primary-header display-flex ${isLandingPage ? 'home-page' : ''}`}>
      <div>
        {isLandingPage ? (
          <Link to="/">
            <img src={bear} className="jump"  alt="cute bear icon"
            style={{  width: '50px', marginLeft: '2rem' }} />
            <img src={welcome} alt="welcome bubble"
            style={{  height: '50px', marginLeft: '0rem' }} />
          </Link>
        ) : (
          <Link to="/" className="logo-fade">
            <img src={logo} alt="tiny logo"  className="sv-logo"/>
          </Link> // ADD FADE IN and scoot it up???
        )}
        </div>

        <div className="header-bg"></div> 
        
        {/* accessibility support & mobile menu */}
        <button className={`mobile-nav-toggle ${hiddenMenu ? '' : 'close-button'}`}
                aria-controls="navigation"
                // aria-expanded="false"
                aria-expanded={!hiddenMenu} onClick={toggleMenu}>
          <span>{hiddenMenu ? 'MENU' : <img src={close}  alt="close button" style={{ width: '2rem', height: '2rem' }}/>}</span>
        </button>

        <nav>
          <ul id="navigation" className={`navigation display-flex ${hiddenMenu ? '' : 'visible'}`} data-visible="false">
            <li className="active">
              <Link to="/about" className="link" onClick={handleLinkClick}>
                <span>ABOUT</span>
              </Link>
            </li>
            <li>
              <Link to="/extras" className="link" onClick={handleLinkClick}>
                <span>EXTRAS</span>
              </Link>
            </li>
            <li>
              <Link to="/credits" className="link" onClick={handleLinkClick}>
                <span>CREDITS</span>
              </Link>
            </li>
          </ul>
        </nav>

    </header>
  )
}