import React from 'react';
import BackToTop from './BackToTop';

/* images and styles */
import '../styles/Extras.css';
import annex from '../assets/images/group_photos/annex.png';
import group from '../assets/images/group_photos/group_1.png';
import groupBaby from '../assets/images/group_photos/group_baby.png';
import juliaBaby from '../assets/images/group_photos/julia_baby.png';
import juliaLinds from '../assets/images/group_photos/julia_n_linds.png';
import ogLogo from '../assets/images/group_photos/og_logo.png';
import pitch from '../assets/images/group_photos/pitch.png';
import Carousel from "./Carousel"; 

export default function Extras() {
  return (
    <div className="Extras">
      <h1 className="extra-header pink" style={{fontSize: '50px'}}>Behind the scenes!</h1>
      <div className="extra-flex">
        <div className="extra-div">
          <img src={annex} alt="group in annex" className="jump img-height"/>
          <p className="text-block" style={{width: 'fit-content'}}>Second week, our group studying in the annex.</p>
        </div>

        <div className="extra-div">
          <img src={group} alt="silly group" className="jump landscape"/>
          <p className="text-block" style={{width: 'fit-content'}}>First official group photo!</p>
        </div>
      </div>

        <Carousel /> 

      <div className="extra-flex">
        <div className="extra-div">
          <img src={groupBaby} alt="group with baby" className="jump landscape"/>
          <p className="text-block" style={{width: 'fit-content'}}>First meeting, featuring baby!</p>
        </div>

        <div className="extra-div">
          <img src={juliaLinds} alt="linds and julia" className="jump portrait"/>
          <p className="text-block" style={{width: 'fit-content'}}>Linds and Julia working at Whitty books.</p>
        </div>
      </div>

        <div className="extra-div">
          <img src={ogLogo} alt="original logo" className="jump sv-og-logo"/>
          <p className="text-block" style={{width: 'fit-content'}}>First imagination of SV logo!</p>
        </div>

      <div className="extra-flex">
        <div className="extra-div">
          <img src={juliaBaby} alt="julia with baby" className="jump portrait"/>
          <p className="text-block" style={{width: 'fit-content'}}>Julia being sweet with the baby.</p>
        </div>

        <div className="extra-div">
          <img src={pitch} alt="pitch day" className="jump landscape"/>
          <p className="text-block" style={{width: 'fit-content'}}>Presenting our pitch on stage for our peers.</p>
        </div>
      </div>
      <BackToTop />
    </div>
  )
}
