import React, { useState } from 'react';

/* images and styles */
import '../styles/Contact.css';
import contact from '../assets/game_pngs/navigation/mail.png';
import group from '../assets/game_pngs/avatars/GroupFilled.png';
import close from '../assets/game_pngs/navigation/Crossmark.png';
import pink_cat from '../assets/game_pngs/navigation/pink_cat.png';
import purp_cat from '../assets/game_pngs/navigation/purp_cat.png';
import bunny from '../assets/game_pngs/navigation/bunny.png';

export default function Contact() {
  const [ submitted, setSubmitted ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    // Extract form data
    const formData = new FormData();
    formData.append('name', event.target.name.value);
    formData.append('message', event.target.message.value);
    formData.append('email', event.target.email.value);
    formData.append('_captcha', false);
  
    // Send POST request to the specified URL with form data
    try {
      const response = await fetch("https://formsubmit.co/ajax/skillville.info@gmail.com", {
        method: "POST",
        body: formData
      });
      
      const data = await response.json();
      console.log(data);
      setSubmitted(true); // Update state to indicate form submission
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading state back to false when the request is completed
    }
  };

  return (
    <div className="contact-page">
       <div className="form-box container">
      {!submitted ? (  
        <div>
          <div className="flex">
            <h1>Send us a letter!</h1>
            <img src={contact} alt="mail icon" className="mail jump" />
          </div>
          <form onSubmit={handleSubmit} className="form-flex">
            <input type="text" name="name" placeholder="Name" className="form-box"required />
            <textarea name="message" placeholder="Message" className="form-box" required></textarea>
            <input type="email" name="email" placeholder="Email address" className="form-box" required />

            <input type="hidden" name="_captcha" value="false" />
            <button className="submit-btn" type="submit" disabled={loading}>
              {loading ? (
                  <div className="loader"></div>
              ) : (
                'submit'
              )}
            </button>
          </form>
        </div>  
      ) : (
        <div className="thank-you">
          <button className="close-position" style={{height: '1rem'}} onClick={() => setSubmitted(false)}>
            <img src={close} alt="close button" className="ty-next"/>
          </button>
          <img src={group} alt="cute animals" className="group-photo"></img>
          <h2>Thank you! Your letter has been sent.</h2>
        </div>
      )}
    </div>
  </div>
   
  );
}
