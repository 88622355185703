import React from "react";
import PhotoCarousel from "./PhotoCarousel";
import '../styles/Carousel.css';

import BTS1 from '../assets/images/group_photos/BTS1.png';
import BTS2 from '../assets/images/group_photos/BTS2.png';
import BTS3 from '../assets/images/group_photos/BTS3.png';
import BTS4 from '../assets/images/group_photos/BTS4.png';
import BTS5 from '../assets/images/group_photos/BTS5.png';
import BTS6 from '../assets/images/group_photos/BTS6.png';
import BTS7 from '../assets/images/group_photos/BTS7.png';
import BTS8 from '../assets/images/group_photos/BTS8.png';
import BTS9 from '../assets/images/group_photos/BTS9.png';
import BTS10 from '../assets/images/group_photos/BTS10.png';
import BTS11 from '../assets/images/group_photos/BTS11.png';
import BTS12 from '../assets/images/group_photos/BTS12.png';
import BTS13 from '../assets/images/group_photos/BTS13.png';
import BTS14 from '../assets/images/group_photos/BTS14.png';
import BTS15 from '../assets/images/group_photos/BTS15.png';
import BTS16 from '../assets/images/group_photos/BTS16.png';
import BTS17 from '../assets/images/group_photos/BTS17.png';
import BTS18 from '../assets/images/group_photos/BTS18.png';
import BTS19 from '../assets/images/group_photos/BTS19.png';

const photos = [ BTS1, BTS2, BTS3, BTS4, BTS5, BTS6, BTS7, 
                 BTS8, BTS9, BTS10, BTS11, BTS12, BTS13, 
                 BTS14, BTS15, BTS16, BTS17, BTS18, BTS19 ]

export default function Carousel() {
  return (
    <div className="carousel-div">
      <PhotoCarousel photos={photos} />
    </div>
  )
}