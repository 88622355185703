import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../styles/Footer.css';
import contact from '../assets/game_pngs/navigation/mail.png';
import home from '../assets/game_pngs/navigation/Home.png';

export default function Footer() {
  const location = useLocation();
  
  const relativePaths = ['/about', '/game-assets', '/extras', '/carousel'];
  const isRelative = relativePaths.includes(location.pathname);

  if (location.pathname === '/game') {
    return null;
  }

  return (
    <footer className={isRelative ? "footer position-relative" : "footer position-fixed"}>
      <div className="footer-container">
        {location.pathname === '/contact' ? (
          <Link to="/" className="contact">
            <button className="contact-button">Go Home
              <img src={home} alt="home icon" className="home-icon"/>
            </button>
          </Link>
        ) : (
          <Link to="/contact" className="contact">
            <button className="contact-button">Contact Us
              <img src={contact} alt="mail icon" className="mail-icon"/>
            </button>
          </Link>
        )}
      </div>
    </footer>
  )
}
