import React, { useState, useEffect, useRef } from 'react';
import '../styles/PhotoCarousel.css';

const PhotoCarousel = ({ photos, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [photos.length, interval]);

  useEffect(() => {
    carouselRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
  }, [currentIndex]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? photos.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        <div className="carousel-inner" ref={carouselRef}>
          {photos.map((photo, index) => (
            <img key={index} src={photo} alt={`Slide ${index + 1}`} className="carousel-item" />
          ))}
        </div>
        <button className="carousel-arrow left" onClick={goToPrevious}>
          &lt;
        </button>
        <button className="carousel-arrow right" onClick={goToNext}>
          &gt;
        </button>
      </div>
      <div className="carousel-indicators">
        {photos.map((_, index) => (
          <span
            key={index}
            className={`carousel-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default PhotoCarousel;