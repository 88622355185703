/* functional imports */
import React from 'react';
import { Link } from 'react-router-dom'

/* styles and images */
import '../styles/AboutCard.css';
import browngh from '../assets/images/icons/gh-brown.png';
import brownli from '../assets/images/icons/li-brown.png';

function AboutCard ({ image, name, spec, description, github, linkedin, website, link }) {
  return (
    <div className="flex">
      <div className="about-card">
        <img src={image} alt="Dev-Avatar" className="avatar"></img>
        <h2>{name}</h2>
        <img src={spec} alt="Spec-logo" className="spec-logo jump"></img>
        <p>{description}</p>
        <div>
        <Link to={github} target="_blank">
          <img src={browngh} alt="github" className="lighten-on-hover" style={{width: '4rem', height: '4rem'}}></img>
        </Link>
        <Link to={linkedin} target="_blank">
          <img src={brownli} alt="linkedin" className="lighten-on-hover" style={{marginLeft: '.5rem', width: '4rem', height: '4rem'}}></img>
        </Link>
        </div>
        <Link to={link} target="_blank" className="web-link">{website}</Link>
      </div>
  </div>
    
  );
};

export default AboutCard