import linds from '../assets/images/profiles/lt.png';
import tey from '../assets/images/profiles/teylor.png';
import jo from '../assets/images/profiles/johanna.png';
import julia from '../assets/images/profiles/julia.png';
import mason from '../assets/images/profiles/mason.png';
import fs from '../assets/images/titles/fs.png';
import arvr from '../assets/images/titles/arvr.png';
import ml from '../assets/images/titles/ml.png'


const aboutCardsData = [
  { image: linds, 
    name: 'Lindsey Thomas', 
    spec: fs, 
    description: 'Passionate software developer, fiber artist, feelings feeler. Responsible for website design and implementation.', 
    github: 'https://github.com/timidgeek/', 
    linkedin: 'https://linkedin.com/in/timidgeek/', 
    website: 'timidgeek.com/', 
    link: 'https://timidgeek.com/' },
  { image: tey, 
    name: 'Teylor Chapman- Bilbee', 
    spec: arvr, 
    description: 'Game developer, enthusiastic mom, gamer girl, self aware. Our Unity expert, responsible for game functionality.',
    github: 'https://github.com/teylorchapman/', 
    linkedin: 'https://www.linkedin.com/in/teylorchapmanbilbee/', 
    // website: 'timidgeek.com', 
    // link: 'https://timidgeek.com/'
    },
  { image: jo, 
    name: 'Johanna Avila', 
    spec: ml, 
    description: "Machine learning engineer. SkillVille's official NPC researcher and designer, girl with a passion for fashion.",
    github: 'https://github.com/jobabyyy/', 
    linkedin: 'https://www.linkedin.com/in/johannaavila1/', 
    // website: 'timidgeek.com', 
    // link: 'https://timidgeek.com/' 
    },
  { image: julia, 
    name: 'Julia Bullard', 
    spec: ml, 
    description: 'Machine learning engineer. Armchair activist, strategic innovator. Responsible for scene layout and project management.',
    github: 'https://github.com/Julia-5534/', 
    linkedin: 'https://www.linkedin.com/in/juliagagulia/', 
    // website: 'timidgeek.com', 
    // link: 'https://timidgeek.com/' 
    },
  { image: mason, 
    name: 'Mason Counts', 
    spec: ml, 
    description: 'Machine learning engineer. Our master tool researcher, big data lover, and certified cool guy.',
    github: 'https://github.com/spindouken/', 
    linkedin: 'https://www.linkedin.com/in/masoncounts/', 
    website: 'masoncounts.com/', 
    link: 'https://masoncounts.com/'  
    },
];

export default aboutCardsData;