import React from 'react';
import '../styles/BackToTop.css';

const BackToTop = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button className="scroll-to-top purple" onClick={handleClick}>
      Back to the top!
    </button>
  );
};

export default BackToTop;