import React, { useState, useEffect } from 'react';
import '../styles/Game.css';
import bear from '../assets/game_pngs/avatars/Oops.png';
import { Link } from 'react-router-dom';
import Oops from './Oops';
import heart from '../assets/game_pngs/icons/heart.png';

export default function Game() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check for desktop
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex">
      {!isDesktop && (
        <Oops />
      )}
      {isDesktop && (
        <div>
          <iframe
            title="SkillVille"
            frameborder="0"
            src="https://itch.io/embed-upload/10158627?color=FFFF85"
            allowfullscreen="" width="800" height="588"><a href="https://teylorchapmanbilbee.itch.io/skillville">Play SkillVille on itch.io</a></iframe>
          <p className="p-block">Please enjoy the SkillVille demo!<br />
    
            Don't hesitate to 
              <Link to="/contact" className="game-link"> contact us </Link>
            if you have any questions or comments.<br></br>
          <div className="sv-team">
            <img src={heart} alt="heart icon" className="plus-sign" style={{height: '30px'}} />,<Link to="/about" className="game-link"> SV team</Link>
          </div>
          </p>
        </div>
      )}
    </div>
  );
}