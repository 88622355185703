import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import useLocation from react-router-dom
import { useLocation } from 'react-router-dom'; // Import useLocation

/* styling imports */
import './App.css';

/* component imports */
import Header from './components/Header';
import Landing from './components/Landing';
import Credits from './components/Credits';
import Game from './components/Game';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Extras from './components/Extras';
import GameAssets from './components/GameAssets';
import Carousel from './components/Carousel'

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isExtrasPage = location.pathname === '/extras';

  return (
    <div className={`App ${isExtrasPage ? 'extras-page' : ''}`}>
      <Header />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/game" element={<Game />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/extras" element={<Extras />} />
        <Route path="/game-assets" element={<GameAssets />} />
        <Route path="/carousel" element={<Carousel />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
